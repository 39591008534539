import React from "react";
import PropTypes from 'prop-types';

const CheckBox = (props) =>{
    const onChangeHandler = (e) => {
        const value = e.target.checked

        if(props.onChange) {props.onChange(value)}
        if(value && props.onChecked) {props.onChecked(value)}
        if(!value && props.onUnChecked) {props.onUnChecked(value)}
    }

    return (
        <label className="checkbox-button">
            <input type="checkbox" name={props.name} onChange={onChangeHandler} checked={props.checked}/>
            <div className="round">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7" />
                </svg>
            </div>
        </label>
    )
}

CheckBox.propTypes  = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    onChecked: PropTypes.func,
    onUnChecked: PropTypes.func,
    checked: PropTypes.bool
}

export default CheckBox
import React, {useEffect, useRef, useState} from "react";
import PageHeader from "../components/PageHeader";
import CheckBox from "../components/CheckBox";
import AxiosInstance from "../utils/AxiosInstance";
import { ReCaptcha } from 'react-recaptcha-v3'
import config from "../consts/config";
import { useNavigate } from 'react-router-dom';
import Calendar from "../components/Calendar";
import CalcDate from "../utils/CalcDate";

const Reservation = (props) => {
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [slots, setSlots] = useState(null)
    const [order, setOrder] = useState([])
    const [clientData, setClientData] = useState({
        name: "",
        email: "",
        tel: "",
        checkbox: "",
        recaptchaToken: "",
        note: ""
    })

    const [sending, setSending] = useState(false)
    const [errorsFromApi, setErrorsFromApi] = useState({})

    const navigate = useNavigate();

    useEffect(() => {
        updateToken()

        setDay(new Date())
    }, [])

    const recaptcha = useRef()

    const setDay = (day) => {
        setSlots(null)
        setSelectedDate(day)

        const date = day.getFullYear()+"-"+(day.getMonth()+1)+"-"+day.getDate()

        AxiosInstance.get('/reservations/slots/'+date)
            .then((res) => {
                setSlots(res.data)
            })
    }

    const update = () => {
        const date = selectedDate.getFullYear()+"-"+(selectedDate.getMonth()+1)+"-"+selectedDate.getDate()

        AxiosInstance.get('/reservations/slots/'+date)
            .then((res) => {
                setSlots(res.data)
            })
    }

    const addToOrder = (slot) => {
        const newOrder = [...order]

        //Duplication Check
        if(!newOrder.find((s) => s.slot.date === slot.date)) {
            newOrder.push({
                slot: slot,
                //players: order.length > 0 ? order[order.length-1].players : null,
                players: null,
                wo: slot.booking === 'BOOKED_WITH_FREE_SLOTS',
                alreadySet: false
            })
        }

        setOrder(newOrder)
    }

    const removeFromOrder = (slot) => {
        const newOrder = [...order].filter((s) => s.slot.date !== slot.date)
        setOrder(newOrder)
    }

    const makeReservation = async () => {
        updateToken()

        const slots = order.map(orderItem => {
            return {
                id: orderItem.slot.id,
                date: orderItem.slot.date.split(' ')[0],
                players: orderItem.players,
                wo: orderItem.wo
            }
        })

        setSending(true)

        AxiosInstance.post('/reservations', {...clientData, slots})
            .then((res) => {
                navigate('/rezervace/'+res.data.code)
                window.gtag('event', 'reservation_success', {
                    slots: slots.length,
                    totalPlayers: slots.reduce((a,b) => a + parseInt(b.players), 0),
                    send_to: 'G-4P48TWG8PB'
                });
            })
            .catch((err) => {
                setErrorsFromApi(err.response.data)
                window.gtag('event', 'reservation_catch', {
                    send_to: 'G-4P48TWG8PB'
                });
                update()
            })
            .finally(() => {
                setSending(false)
            })
    }

    const verifyCallback = (recaptchaToken) => {
        setClientData({...clientData, recaptchaToken: recaptchaToken})
    }

    const updateToken = () => {
        recaptcha.current.execute();
    }

    const setWo = (slotDate, value) => {
        const newOrder = [...order]
        const slot = newOrder.find((s) => s.slot.date === slotDate)
        slot.wo = value
        setOrder(newOrder)
    }

    const setPlayers = (slotDate, value) => {
        let newOrder = [...order]
        const slot = newOrder.find((s) => s.slot.date === slotDate)
        slot.players = value
        slot.alreadySet = true

        const isAlreadySet = newOrder.find((s) => s.slot.date !== slotDate && s.alreadySet)

        if(!isAlreadySet) {
            //console.log(slot, isAlreadySet, !isAlreadySet)
            for(const ord of newOrder) {
                ord.players = value
            }
        }

        setOrder(newOrder)
    }

    return (
        <>
            <PageHeader title="Rezervace"/>
            <div className="page-reservation">
                <div className="container">
                    <section>
                        <h2 className="step">
                            <div className="step-count">1.</div>
                            Vyber datum
                        </h2>
                        <Calendar selectedDate={selectedDate} setDate={setDay}/>
                    </section>


                    <h2 className="step">
                        <div className="step-count">2.</div>
                        Vyber časy tvých her
                        - {["Neděle", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota"][selectedDate.getDay()]}{" "}
                        {selectedDate.getDate()}{". "}
                        {selectedDate.getMonth() + 1}{". "}
                        {selectedDate.getFullYear()}
                    </h2>
                </div>

                <div className="timeTable">
                    {
                        slots ? (
                            slots.map(slot => {
                                const alreadyInOrder = order.find(orderSlot => orderSlot.slot.date === slot.date)

                                let color = slot.booking

                                if(new Date(slot.date) < new Date()) {
                                    color = "ALREADY_PASSED"
                                }

                                return (
                                    <div className={"timeSlot "+color}>
                                        <div className="check">
                                            {
                                                slot.booking !== "FULLY_BOOKED" && slot.booking !== "ALREADY_PASSED" ? (
                                                    <CheckBox
                                                        checked={!!alreadyInOrder}
                                                        onChecked={() => addToOrder(slot)}
                                                        onUnChecked={() => removeFromOrder(slot)}
                                                    />
                                                ) : (
                                                    <CheckBox
                                                        checked={false}
                                                        disabled
                                                    />
                                                )
                                            }
                                        </div>
                                        <div className="info">
                                            <div className="time">{slot.time}</div>
                                            <div className="slots">{slot.openSlots} míst</div>
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            "Loading..."
                        )
                    }
                </div>

                <div className="container">
                    <section>
                        <h2 className="step">
                            <div className="step-count">3.</div>
                            Kolik hráčů bude hrát
                        </h2>
                        <div className="game-detail">
                            {
                                order.length === 0 ? (
                                    <div className="empty">
                                        Vyber si čas tvých her z výběru!
                                    </div>
                                ) : (
                                    order.sort((a, b) => (CalcDate.getDateFromString(a.slot.date) > CalcDate.getDateFromString(b.slot.date)) ? 1 : -1).map(item =>
                                        <div className="order-item" key={item.slot.date}>
                                            <div className="date">
                                                <div className="day">
                                                    {
                                                        CalcDate.getWordDate(item.slot.date)
                                                    }
                                                </div>

                                                <div className="time">{item.slot.time}</div>
                                            </div>
                                            <div className="players">
                                                <input type="number"
                                                       defaultValue={item.players}
                                                       onChange={(e) => setPlayers(item.slot.date, e.target.value)}
                                                       min={1}
                                                       max={item.slot.openSlots}/>{"  "}
                                                hráčů (max {item.slot.openSlots})
                                            </div>
                                            <div className="status">
                                                {
                                                    item.slot.booking !== "FREE"  ? (
                                                        <span className="wo">S cizími hráči</span>
                                                    ) : (
                                                        <label>
                                                            <CheckBox
                                                                checked={item.slot.wo}
                                                                onChecked={() => setWo(item.slot.date, true)}
                                                                onUnChecked={() => setWo(item.slot.date, false)}
                                                            /> Povolit cizí hráče
                                                        </label>
                                                    )

                                                }
                                            </div>
                                            <div className="remove" onClick={() => removeFromOrder(item.slot)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                          d="M6 18L18 6M6 6l12 12"/>
                                                </svg>
                                            </div>

                                            {
                                                [""].map(() => {
                                                    const d = CalcDate.getDateFromString(item.slot.date)
                                                    const id = "slot."+
                                                        d.getFullYear()+"-"+
                                                        ((d.getMonth()+1).toString() < 10 ? "0"+(d.getMonth()+1) : (d.getMonth()+1))+"-"+
                                                        (d.getDate().toString() < 10 ? "0"+d.getDate() : d.getDate())+
                                                        "."+item.slot.id
                                                    return (
                                                        <div className="error" id={id}>
                                                            {errorsFromApi[id]}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                )
                            }

                            <div className="error">{errorsFromApi.slots}</div>
                        </div>
                    </section>

                    <section>
                        <h2 className="step">
                            <div className="step-count">4.</div>
                            Kontakt
                        </h2>
                        <div className="contact">
                            <div className="row-3">
                                <div className="c">
                                    <div className="title">Jméno *</div>
                                    <input type="text" placeholder="Zadejte jméno" autoComplete="name"
                                           onChange={(e) => setClientData({...clientData, name: e.target.value})}/>
                                    <div className="error">{errorsFromApi.name}</div>
                                </div>

                                <div className="c">
                                    <div className="title">Telefon *</div>
                                    <input type="tel" placeholder="Zadejte telefon" autoComplete={"tel"}
                                           onChange={(e) => setClientData({...clientData, tel: e.target.value})}/>
                                    <div className="error">{errorsFromApi.tel}</div>
                                </div>

                                <div className="c">
                                    <div className="title">Email *</div>
                                    <input type="email" placeholder="Zadejte email" autoComplete={"email"}
                                           onChange={(e) => setClientData({...clientData, email: e.target.value})}/>
                                    <div className="error">{errorsFromApi.email}</div>
                                </div>
                            </div>

                            <div className="row-1">
                                <div className="c">
                                    <div className="title">Poznámka</div>
                                    <textarea
                                        placeholder="Zadejte vlasní poznámku"
                                        onChange={(e) => setClientData({
                                            ...clientData,
                                            note: e.target.value
                                        })}></textarea>
                                    <div className="error">{errorsFromApi.note}</div>
                                </div>
                            </div>
                        </div>

                        <div>
                        <label className="agreement">

                            <CheckBox
                                checked={clientData.checkbox}
                                onChecked={() => setClientData({...clientData, checkbox: true})}
                                onUnChecked={() => setClientData({...clientData, checkbox: false})}
                            /> <p>souhlasím s provozním řádem a s tím, že o něm poučím všechny účastníky mé
                            rezervace <a href={"/provozni-rad"} target="_blank" rel="noopener noreferrer">(zobrazit)</a></p>
                        </label>
                            <div className="error">{errorsFromApi.checkbox}</div>
                        </div>

                        <div className="captcha-box">
                            <ReCaptcha
                                ref={recaptcha}
                                sitekey={config.recaptchaApiKey}
                                action='reservation'
                                verifyCallback={verifyCallback}
                            />
                            <div className="error">{errorsFromApi.recaptchaToken}</div>
                            <div className="error">{errorsFromApi.all}</div>
                        </div>

                        <div className="submit">
                            {
                                sending ? (
                                    <div className="submit-btn disabled">Rezervace se odesílá...</div>
                                ) : (
                                    <div className="submit-btn" onClick={makeReservation}>Zarezervovat</div>
                                )
                            }

                        </div>
                    </section>

                </div>
            </div>
        </>
    )
}

export default Reservation

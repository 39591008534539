import React from "react";

const Loader = (props) => {
    return (
        <div className="full-loader">
            <div className="loader">
                {
                    props.error ? (
                        <img src="/img/alert-triangle.svg" alt="error" className="bounce"/>
                    ) : (
                        <img src="/img/loader.svg" alt="loader" className="spin"/>
                    )
                }


                <div className="text">
                    <h2>
                        {
                            props.error ? (
                                "Omlouváme se, nastala chyba při načítání stránky."
                            ) : (
                                "Načítání..."
                            )
                        }
                    </h2>

                    {
                        props.error && props.errorButton && <div className="btn btn-success" onClick={() => props.errorButton()}>Zkusit načíst znovu</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Loader
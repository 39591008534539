import React, {useEffect, useState} from "react";
import months from "../../consts/months";
import {Link} from "react-router-dom";
import AxiosInstance from "../../utils/AxiosInstance";

const News = (props) => {
    const {news} = props


    const getDay = (date) => {
        const d = new Date(date)

        return d.getDate() + '. ' + months.months2[d.getMonth()] + ' ' + d.getFullYear()
    }

    return (
        <div className="news">
            <div className="container">
                <h1 className="main">Aktuality z naší arény</h1>
            </div>
            <div className="news-list">
                {
                    news && news.map((newsItem) =>
                        <div className="new">
                            <div className="type">
                                {
                                    newsItem.type === "OPENING-HOURS" ? (
                                        <>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        otevírací doba
                                        </>
                                    ) : (
                                        <>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            info
                                        </>
                                    )
                                }
                            </div>
                            <div className="title">{newsItem.title}</div>
                            <p>{newsItem.short_text}</p>

                            <div className="bottom">
                                <div className="date">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                    </svg>
                                    {getDay(newsItem.date)}
                                </div>
                                <Link to={"/aktualita/"+newsItem.id}><div className="link">Celá aktualita</div></Link>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default News
import React, {useState} from "react";
import months from "../consts/months";

const Calendar = (props) => {
    const [year, setYear] = useState(new Date().getFullYear())
    const [month, setMonth] = useState(new Date().getMonth()+1)

    const fixWeekStarting = {
        0: 6, //Sunday
        1: 0, //Monday
        2: 1, //Tuesday
        3: 2, //Wednesday
        4: 3, //Thursday
        5: 4, //Friday
        6: 5 //Saturday
    }

    const fixWeekEnding = {
        0: 0, //Sunday
        1: 6, //Monday
        2: 5, //Tuesday
        3: 4, //Wednesday
        4: 3, //Thursday
        5: 2, //Friday
        6: 1 //Saturday
    }

    const handleMove = (num) => {
        let newMonth = month + num;

        //New Year skip
        if(newMonth > 12) {
            setYear(year+1)
            newMonth = 1
        }

        //Old year skip
        if(newMonth < 1) {
            setYear(year-1)
            newMonth = 12
        }

        setMonth(newMonth)
    }

    const setToday = () =>{
        setMonth(new Date().getMonth()+1)
        setYear(new Date().getFullYear())
        props.setDate(new Date())
    }

    const isActive = (date) => {
        const today = new Date()
        today.setHours(0, 0, 0, 0)

        return today <= date
    }

    const handleSelect = (date) => {
        if(isActive(date)) {
            props.setDate(date)
        }
    }

    const isSameDay = (day1, day2) => {
        return day1.getDate() === day2.getDate() && day1.getMonth() === day2.getMonth() && day1.getFullYear() === day2.getFullYear()
    }

    const getDayClass = (index) => {
        const day = new Date(year, month-1, index+1)

        if(isSameDay(day, props.selectedDate)) {
            return "day selected"
        }

        if(!isActive(day)) {
            return "day old"
        }

        return "day"
    }

    const daysBefore = fixWeekStarting[new Date(year, month-1, 1).getDay()]
    const daysAfter = fixWeekEnding[(new Date(year, month, 0)).getDay()]

    return (
        <div className="calendar">
            <div className="title">
                <h2>{months.months[month-1]} {year}</h2>
                <div className="move">
                    <div className="prev" onClick={() => handleMove(-1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                        </svg>
                    </div>
                    <div className="today" onClick={() => setToday()}>Dnes</div>
                    <div className="next" onClick={() => handleMove(1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                    </div>
                </div>
            </div>
            <div className="days-grid">
                {
                    [...Array(daysBefore)].map((day) =>
                        <div className="empty"></div>
                    )
                }
                {
                    [...Array(new Date(year, month, 0).getDate())].map((day, index) =>
                        <div className={getDayClass(index)}
                             onClick={() => handleSelect(new Date(year, month-1, index+1))}>
                            <div className="date">{index+1}.</div>
                            <div className="month">{months.months2[month-1]}</div>
                        </div>
                    )
                }
                {
                    [...Array(daysAfter)].map((day) =>
                        <div className="empty"></div>
                    )
                }
            </div>
        </div>
    )
}

export default Calendar
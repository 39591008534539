import React, {useContext, useEffect} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import "./css/style.scss"
import ConfigContextProvider, {ConfigContext} from "./providers/ConfigProvider";
import IndexContextProvider from "./providers/IndexProvider";
import Loader from "./components/Loader";
import MenuContextProvider, {MenuContext} from "./providers/MenuProvider";

const LoaderWrapper = () => {
    const config = useContext(ConfigContext)
    const menus = useContext(MenuContext)

    const tryAgain = () => {
        if (config.error) {
            config.tryAgain()
        }

        if (menus.error) {
            menus.tryAgain()
        }
    }

    if (!config.email || !menus.main) {
        return <Loader error={config.error || menus.error} errorButton={tryAgain}/>
    }

    return (
        <App/>
    )
}

ReactDOM.render(
    <React.StrictMode>
        <ConfigContextProvider>
            <IndexContextProvider>
                <MenuContextProvider>
                    <LoaderWrapper/>
                </MenuContextProvider>
            </IndexContextProvider>
        </ConfigContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

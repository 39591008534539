import React, {createRef} from "react";

class DragList extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            scrolling: false,
            scrolledLeft: 0
        }

        this.swipe = {
            startClickX: 0,
            startSrollPosition: 0,
        }

        this.scrollBox = createRef()
        this.moveableBox = createRef()
    }

    onMouseDown(e) {
        //e.preventDefault()
        this.setState({scrolling: true})

        const pageX = this.getPageX(e)

        this.start = pageX

        this.swipe = {
            startClickX: pageX,
            startSrollPosition: this.scrollBox.current.scrollLeft
        }
    }

    loopJump() {
        const w = this.moveableBox.current.offsetWidth / 3

        this.scrollBox.current.style.scrollBehavior = 'auto'

        const scroll = this.scrollBox.current.scrollLeft

        if(scroll > w+w+(w/4*1)) {
            this.scrollBox.current.scrollLeft = scroll - w
        }

        if(scroll < (w/4*3)) {
            this.scrollBox.current.scrollLeft = scroll + w
        }

        this.scrollBox.current.style.scrollBehavior = this.state.scrolling ? "auto" : "smooth"
        this.setState({scrolledLeft: this.scrollBox.current.scrollLeft})
    }

    getPageX(e) {
        if(!e.pageX && e.changedTouches) {
            return e.changedTouches[0].pageX
        }else {
            return e.pageX
        }
    }

    onMouseUp(e) {
        //e.preventDefault()
        this.setState({scrolling: false})

        if(this.props.slide) {
            this.fixSlide()
        }

        if(this.props.loop) {
            this.loopJump()
        }

        window.gtag('event', 'photo_carousel_move', {
            send_to: 'G-4P48TWG8PB'
        });
    }

    fixSlide() {
        const oneElementWidth = this.moveableBox.current.offsetWidth / this.moveableBox.current.childElementCount

        const move = Math.round(this.scrollBox.current.scrollLeft / oneElementWidth) * oneElementWidth
        this.scrollBox.current.scrollLeft = move
        this.setState({scrolledLeft: move})
    }

    onMouseMove(e) {
        if(this.state.scrolling) {
            //e.preventDefault()

            const pageX = this.getPageX(e)

            const moveCursor = pageX - this.swipe.startClickX
            this.scrollBox.current.scrollLeft = this.swipe.startSrollPosition + (moveCursor*(-1))
            this.setState({scrolledLeft: this.scrollBox.current.scrollLeft})

            if(this.props.loop) {
                this.loopJump()
            }
        }
    }

    componentDidMount() {
        const div = this.scrollBox.current
        div.addEventListener('mousedown', this.onMouseDown.bind(this));
        div.addEventListener('touchstart', this.onMouseDown.bind(this));

        div.addEventListener('mouseleave', this.onMouseUp.bind(this));
        div.addEventListener('mouseup', this.onMouseUp.bind(this));
        div.addEventListener('touchend', this.onMouseUp.bind(this));

        div.addEventListener('mousemove', this.onMouseMove.bind(this));
        div.addEventListener('touchmove', this.onMouseMove.bind(this));

        if(this.props.loop) {
            this.scrollBox.current.scrollLeft = this.moveableBox.current.offsetWidth / 3
            this.setState({scrolledLeft: this.scrollBox.current.scrollLeft})
        }
    }

    componentWillUnmount() {
        const div = this.scrollBox.current
        div.removeEventListener('mousedown', this.onMouseDown);
        div.removeEventListener('touchstart', this.onMouseDown);

        div.removeEventListener('mouseleave', this.onMouseUp);
        div.removeEventListener('mouseup', this.onMouseUp);
        div.removeEventListener('touchend', this.onMouseUp);

        div.removeEventListener('mousemove', this.onMouseMove);
        div.removeEventListener('touchmove', this.onMouseMove);
    }

    move(items) {
        const oneElementWidth = this.moveableBox.current.offsetWidth / this.moveableBox.current.childElementCount

        const move = oneElementWidth * items;

        this.scrollBox.current.scrollLeft = this.scrollBox.current.scrollLeft + move
        this.setState({scrolledLeft: this.scrollBox.current.scrollLeft})

        if(this.props.slide) {
            this.fixSlide()
        }

        if(this.props.loop) {
            this.loopJump()
        }
    }

    elementWidth() {
        return this.moveableBox?.current?.offsetWidth - this.scrollBox?.current?.offsetWidth || 200
    }

    render() {
        return (
            <div className="draggable-container">
                <div className="drag-frame" ref={this.scrollBox} style={{scrollBehavior: this.state.scrolling ? "auto" : "smooth"}}>
                    <div className="drag-list" ref={this.moveableBox}>
                        {
                            this.props.loop && this.props.children
                        }
                        {this.props.children}
                        {
                            this.props.loop && this.props.children
                        }
                    </div>
                </div>
                <div className="arrow left" onClick={() => this.move(-1)}
                     style={{display: this.props.loop || (this.state.scrolledLeft > 10) ? 'flex' : 'none'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                </div>
                <div className="arrow right" onClick={() => this.move(1)}
                     style={{display: this.props.loop || (this.state.scrolledLeft < (this.elementWidth() - 10)) ? 'flex' : 'none'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                </div>
            </div>
        )
    }
}

export default DragList
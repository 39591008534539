import React from "react";
import {Link} from "react-router-dom";

const Error = (props) => {
    const errorCode = props.errorCode || 404;

    const codes = {
        404: {
            title: "Chyba 404",
            text: "Vámi požadovaná stránka neexistuje!"
        },
        500: {
            title: "Chyba 500",
            text: "Nastala chyba na straně serveru"
        }
    }

    return (
        <div className="page-error404">
            <div className="error-title">{codes[errorCode].title}</div>
            <p>{codes[errorCode].text}</p>
            <Link to='/'>
                <div className="btn btn-success">Zpět na úvodní stránku</div>
            </Link>
        </div>
    )
}

export default Error
import {Link} from "react-router-dom";
import React, {useContext} from "react"
import {MenuContext} from "../providers/MenuProvider";

const Footer = () => {
    const menu = useContext(MenuContext)

    return (
        <div className="footer">
            <div className="container footer-main-frame">
                <div className="logo">
                    <Link to="/"><img src={`/img/${process.env.REACT_APP_LOGO}`} alt="Logo"/></Link>
                </div>
                <div className="footer-links">
                    {
                        menu.footer.map(item =>
                            <Link key={item.href} to={item.href}>{item.title}</Link>
                        )
                    }
                </div>
            </div>
            <div className="container footer-signature">
                <div className="sign">© Laser Game Česká Lípa 2023</div>
                <div className="links"></div>
            </div>
        </div>
    )
}

export default Footer

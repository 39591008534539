import months from "../consts/months";

class CalcDate {
    getDateFromString(string) {
        string = string.replace(/ /g, "T")+"Z"
        const time = Date.parse(string)
        return new Date(time)
    }

    getSplitDate(date) {
        return {
            date: date.getDate(),
            month: date.getMonth()+1,
            monthWord: months.months2[date.getMonth()],
            fullYear: date.getFullYear(),
        }
    }

    getWordDate(string) {
        const date = this.getSplitDate(this.getDateFromString(string))
        return date.date +". "+date.monthWord+" "+date.fullYear
    }
}

export default new CalcDate()
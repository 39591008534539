import React, {useContext, useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import AxiosInstance from "../utils/AxiosInstance";
import {ConfigContext} from "../providers/ConfigProvider";

const DoneReservation = () => {
    const {code} = useParams()

    const configContext = useContext(ConfigContext);

    return (
        <div className="page-doneReservation">
            <div className="ticket">
                <div className="header">
                    <h1>Dokončená rezervace</h1>
                    <div className="number">kód rezervace: <div className="code">{code}</div></div>
                </div>
                <div className="ticket-content" dangerouslySetInnerHTML={{__html: configContext.reservation_done_text}}>
                </div>
                <div className="sign">
                    Těšíme se na Vaší návštěvu.
                </div>
            </div>
        </div>
    )
}

export default DoneReservation
import {ReCaptcha} from "react-recaptcha-v3";
import config from "../consts/config";
import React, {useState} from "react";
import AxiosInstance from "../utils/AxiosInstance";

const ContactForm = () => {
    const [contactForm, setContactForm] = useState({
        name: "",
        contact: "",
        text: "",
        recaptchaToken: ""
    })

    const recaptcha = React.createRef()

    const [contactFormErrors, setContactFormErrors] = useState({})

    const [sending, setSending] = useState(false)

    const [done, setDone] = useState(false)

    const verifyCallback = (recaptchaToken) => {
        setContactForm({...contactForm, recaptchaToken: recaptchaToken})
    }

    const getToken = () => {
        setContactForm({...contactForm, recaptchaToken: ""})

        return new Promise((resolve => {
            updateToken()

            while (true) {
                if(contactForm.recaptchaToken) {
                    resolve(contactForm.recaptchaToken)
                    break;
                }
            }

            setTimeout(() => {
                resolve("")
            }, 3000)
        }))
    }

    const updateToken = () => {
        recaptcha.current.execute();
    }

    const send = async () => {
        setSending(true)
        await getToken()

        AxiosInstance.put('/contact', contactForm)
            .then((res) => {
                setContactFormErrors({})
                setContactForm({
                    name: "",
                    contact: "",
                    text: "",
                    recaptchaToken: ""
                })
                setDone(true)
            })
            .catch((err) => {
                err?.response?.data ? setContactFormErrors(err.response.data) : setContactFormErrors("Nastala chyba serveru, zkuste to později")
            })
            .finally(() => {
                setSending(false)
            })
    }

    if(done) {
        return (
            <div className="container contact-form">
                <h1 className="main">Kontaktní formulář</h1>

                <div className="text-center">
                    <h3 className="">Díky za zprávu! Na Váš dotaz Vám brzy odpovíme!</h3>
                    <div className="submit-btn" onClick={() => setDone(false)}>Odeslat další dotaz</div>
                </div>
            </div>
        )
    }

    return (
        <div className="container contact-form">
            <h1 className="main">Kontaktní formulář</h1>
            <div className="row-2">
                <div className="c">
                    <div className="title">Jméno *</div>
                    <input type="text" placeholder="Zadejte jméno" autoComplete="name"
                           onChange={(e) => setContactForm({...contactForm, name: e.target.value})}/>
                    <div className="error">{contactFormErrors.name}</div>
                </div>

                <div className="c">
                    <div className="title">Kontakt *</div>
                    <input type="text" placeholder="Zadejte email, nebo telefon"
                           onChange={(e) => setContactForm({...contactForm, contact: e.target.value})}/>
                    <div className="error">{contactFormErrors.contact}</div>
                </div>
            </div>
            <div className="row-1">
                <div className="c">
                    <div className="title">Váš dotaz *</div>
                    <textarea
                        placeholder="Zadejte Váš dotaz"
                        onChange={(e) => setContactForm({...contactForm, text: e.target.value})}></textarea>
                    <div className="error">{contactFormErrors.text}</div>
                </div>
            </div>

            <ReCaptcha
                ref={recaptcha}
                sitekey={config.recaptchaApiKey}
                action='reservation'
                verifyCallback={verifyCallback}
            />
            <div className="error">{contactFormErrors.recaptchaToken}</div>

            <div className="text-right">
                {
                    sending ? (
                        <div className="submit-btn disabled">Odeslat dotaz</div>
                    ) : (
                        <div className="submit-btn" onClick={send}>Odeslat dotaz</div>
                    )
                }

            </div>
        </div>
    )
}

export default ContactForm
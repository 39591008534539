import Menu from "./menu";
import React, {useEffect} from "react";
import Footer from "./Footer";
import { useNavigate } from 'react-router-dom';

const Layout = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
        //console.log(window.location.pathname + window.location.search, history.location)

        /*
        window.gtag('event', 'page_view', {
            page_location: window.location.pathname + window.location.search,
            page_path: history.location.pathname,
            send_to: 'G-4P48TWG8PB'
        })

        window.gtag('event', 'screen_view', {
            screen_name: history.location.pathname,
            send_to: 'G-4P48TWG8PB'
        });

         */
    }, [window.location])

    return (
        <>
            <Menu scroller={props.scrolled}/>
                <div className="content">
                    {props.children}
                </div>
            <Footer/>
        </>
    )
}

export default Layout


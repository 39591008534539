import React from "react";

const PageHeader = (props) =>{
    return (
        <div className="page-header">
            <div className="bg"/>
            <div className="text">
                <div className="container">
                    <h1 className="page-title">{props.title}</h1>
                </div>
            </div>
        </div>
    )
}

export default PageHeader
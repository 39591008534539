import React, {useContext, useEffect} from "react";
import leaflet from "leaflet"
import config from "../../consts/config";
import {ConfigContext} from "../../providers/ConfigProvider";

const Map = () => {
    const configContext = useContext(ConfigContext);

    useEffect(() => {
        const map = leaflet.map('contact-map', {

        }).setView([50.6819567, 14.5063847], 13);

        leaflet.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
            attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
            maxZoom: 18,
            id: 'jetam/ckj0jw4ps0zsd1arucdbkiy4t',
            tileSize: 512,
            zoomOffset: -1,
            accessToken: config.mapbox.token
        }).addTo(map);

        leaflet.marker([50.6819567, 14.5363847], {

        }).addTo(map)
    }, [])

    console.log(configContext)

    return (
        <div className="map-frame">
            <div className="address">

                <h2 className="main sm">Kde nás najdete</h2>

                <div className="cols-2">
                    <div>
                        <div className="title">Adresa</div>
                        <address>
                            <p dangerouslySetInnerHTML={{__html: configContext.adress}}></p>
                        </address>
                    </div>
                    <div>
                        <div className="title">Telefon</div>
                        <a href={"tel:" + configContext.tel}>(+420) {configContext.tel}</a>

                        <div className="title">Email</div>
                        <a href={"mailto:" + configContext.email}>{configContext.email}</a>
                    </div>
                </div>
            </div>

            <div id="contact-map"></div>
        </div>
    )
}

export default Map
